import React, { useState, useEffect } from "react";
import { BasicFormWrapper } from "../../../../containers/styled";
import Heading from "../../../heading/heading";
import { Cards } from "../../../cards/frame/cards-frame";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { connect } from "react-redux";
import { formatter } from "../../../../helpers/helper";
import {
  calculateSquareFootCost,
  calculateRemoveSealingCost,
} from "../../../helpers/helper";
import { Form, Input, Col, Skeleton } from "antd";
// import { Button } from "../../../buttons/buttons";
// import { fetchPromoCodesAction } from "../../../../redux/actions/promoCodeActions";
// import moment from "moment";

function QuoteReview(props) {
  const {
    form,
    /*fetchPromoCodes, promoCodes,*/ loading,
    setStep,
    roofObjects,
  } = props;

  useEffect(() => {
    // if (!promoCodes || (promoCodes && promoCodes.length === 0)) {
    //   fetchPromoCodes();
    // }
    // eslint-disable-next-line
  }, []);

  const [formValues, setFormValues] = useState({ promoCodes: [] });
  // const [promoCodeError, setPromoCodeError] = useState("");

  useEffect(() => {
    setFormValues({ ...formValues, ...form.getFieldsValue(true) });
    // eslint-disable-next-line
  }, [form]);

  useEffect(() => {
    form.setFieldsValue({
      ...form.getFieldsValue(true),
      // promoCodes: formValues.promoCodes,
      comments: formValues.comments,
    });

    // eslint-disable-next-line
  }, [/*formValues.promoCodes,*/ formValues.comments]);

  // const handleRemovePromoCode = (promoCodeId) => {
  //   setPromoCodeError("");

  //   setFormValues({
  //     ...formValues,
  //     promoCodes: [
  //       ...formValues.promoCodes.filter((p) => p.id !== promoCodeId),
  //     ],
  //   });
  // };

  // const generatePromoCodes = () => {
  //   return formValues.promoCodes.map((p) => (
  //     <li className="promo-code-item">
  //       <strong
  //         className="text-info"
  //         style={{
  //           paddingRight: "8px",
  //           maxWidth: "70%",
  //           wordBreak: "break-word",
  //         }}
  //       >
  //         {" "}
  //         {p.code} ({formatter.format(p.discount / 100)} OFF)
  //       </strong>{" "}
  //       applied{" "}
  //       <FeatherIcon
  //         style={{ paddingLeft: "8px", width: "16px !important" }}
  //         className="text-danger"
  //         icon="trash-2"
  //         onClick={() => handleRemovePromoCode(p.id)}
  //       />
  //     </li>
  //   ));
  // };

  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.id]: e.target.value });
  };

  // const handlePromoSubmit = (e) => {
  //   e.preventDefault();
  //   setPromoCodeError("");

  //   // Update this to filter promo codes based on current date and if the promo code is enabled
  //   let filteredPromoCodes = promoCodes.filter((p) => {
  //     return (
  //       p.is_enabled &&
  //       moment()
  //         .startOf("day")
  //         .diff(moment(p.valid_from).startOf("day"), "days") >= 0 &&
  //       moment()
  //         .startOf("day")
  //         .diff(moment(p.valid_until).startOf("day"), "days") <= 0
  //     );
  //   });

  //   if (formValues.promoCode) {
  //     let promoCode = filteredPromoCodes.find(
  //       (p) => p.code.toUpperCase() === formValues.promoCode.toUpperCase()
  //     );

  //     if (promoCode) {
  //       if (!formValues.promoCodes.includes(promoCode)) {
  //         setFormValues({
  //           ...formValues,
  //           promoCodes: [...formValues.promoCodes, promoCode],
  //         });
  //       } else {
  //         setPromoCodeError(`Promo Code ${promoCode.code} already used.`);
  //       }
  //     } else {
  //       setPromoCodeError(`Promo Code ${formValues.promoCode} not found.`);
  //     }
  //   }
  // };

  const generateDiscountPrice = () => {
    // if (formValues.promoCodes && formValues.promoCodes.length > 0) {
    //   let result = formValues.promoCodes.reduce((sumDiscount, p) => {
    //     return (sumDiscount += parseInt(p.discount, 10));
    //   }, 0);

    //   return result / 100;
    // }

    return 0;
  };

  const generateRoofObjects = () => {
    if (formValues.roof_objects && formValues.roof_objects.length > 0) {
      return formValues.roof_objects.map((ro) => {
        let roofObject = roofObjects.find(
          (roofObject) => parseInt(roofObject.id, 10) === parseInt(ro.id, 10)
        );
        return (
          <p id={ro.id}>
            {roofObject.name} - {ro.quantity} -{" "}
            {formatter.format((roofObject.cost / 100) * ro.quantity)}
          </p>
        );
      });
    }
  };

  const calculateRoofQuoteCost = (quote) => {
    let totalCost = 0;

    // Add square foot cost
    totalCost += parseFloat(calculateSquareFootCost(quote["square_foot"], 1));

    // Add parapet cost
    if (quote.has_parapet) {
      totalCost += quote.parapet_foot * 3;
    }

    // Add puddle cost
    if (quote.has_puddle) {
      totalCost += quote.puddle_square_foot * 3;
    }

    // Add roof sealing removal cost
    if (quote.remove_roof_sealing) {
      totalCost += parseFloat(
        calculateRemoveSealingCost(quote["square_foot"], 1)
      );
    }

    // Add roof objects removal cost
    if (quote.roof_objects && quote.roof_objects.length > 0) {
      totalCost += quote.roof_objects.reduce((accumulator, item) => {
        let roofObject = roofObjects.find(
          (roofObject) => parseInt(roofObject.id, 10) === parseInt(item.id, 10)
        );

        return (
          accumulator + parseFloat(item.quantity) * (roofObject.cost / 100)
        );
      }, 0);
    }

    return totalCost;
  };

  return (
    <BasicFormWrapper style={{ width: "100%" }}>
      {loading && (
        <Col sm={24} xs={24}>
          <Cards headless>
            <Skeleton paragraph={{ rows: 20 }} active />
          </Cards>
        </Col>
      )}
      {!loading && (
        <div className="atbd-review-order" style={{ width: "100%" }}>
          <Heading as="h4">4. Revisar y Confirmar Cotización</Heading>
          <Cards
            bodyStyle={{ backgroundColor: "#F8F9FB", borderRadius: 10 }}
            headless
          >
            <div className="atbd-review-order__single">
              <Cards headless>
                <div className="atbd-review-order__shippingTitle">
                  <Heading as="h5">
                    Información del Cliente
                    <Link to="#" onClick={() => setStep(0)}>
                      <FeatherIcon icon="edit" />
                      Editar
                    </Link>
                  </Heading>
                </div>
                <article className="atbd-review-order__shippingInfo">
                  <p>Nombre del cliente: {formValues["customer_name"]}</p>
                  <p>Dirección: {formValues["address_line1"]}</p>
                  <p>Municipio: {formValues["municipality"]}</p>
                  <p>País: {formValues["state"]}</p>
                </article>
              </Cards>
            </div>
            <div className="atbd-review-order__single">
              <Cards headless>
                <div className="atbd-review-order__shippingTitle">
                  <Heading as="h5">
                    Detalles de Proyecto
                    <Link to="#" onClick={() => setStep(1)}>
                      <FeatherIcon icon="edit" />
                      Editar
                    </Link>
                  </Heading>
                </div>
                {/* <p>
                  Has Additional Elements:{" "}
                  {formValues["has_additional_elements"] ? "true" : "false"}
                </p> */}
                <p>Pies cuadrados: {formValues["square_foot"]}</p>
                {/* <p>Membrana: {formValues["membrane"]}</p> */}
                <p>
                  Costo estimado:{" "}
                  {formatter.format(
                    calculateSquareFootCost(formValues["square_foot"], 1)
                  )}
                </p>
                <hr></hr>
                <p>Tiene pretil: {formValues["has_parapet"] ? "si" : "no"}</p>
                {formValues["has_parapet"] && (
                  <>
                    <p>Pies: {formValues["parapet_foot"]}</p>
                    <p>
                      Costo estimado:{" "}
                      {formatter.format(formValues["parapet_foot"] * 3)}
                    </p>
                    <hr></hr>
                  </>
                )}
                <p>
                  Tiene empozamiento: {formValues["has_puddle"] ? "si" : "no"}
                </p>
                {formValues["has_puddle"] && (
                  <>
                    <p>Pies cuadrados: {formValues["puddle_square_foot"]}</p>
                    <p>
                      Costo estimado:{" "}
                      {formatter.format(formValues["puddle_square_foot"] * 3)}
                    </p>
                  </>
                )}
              </Cards>
            </div>
            <div className="atbd-review-order__single">
              <Cards headless>
                <div className="atbd-review-order__shippingTitle">
                  <Heading as="h5">
                    Remoción o Corrección de Objetos
                    <Link to="#" onClick={() => setStep(2)}>
                      <FeatherIcon icon="edit" />
                      Editar
                    </Link>
                  </Heading>
                  <p>
                    Remover sellado existente:{" "}
                    {formValues["remove_roof_sealing"] ? "si" : "no"}
                  </p>
                  {formValues["remove_roof_sealing"] && (
                    <>
                      <p>
                        Costo estimado:{" "}
                        {formatter.format(
                          calculateRemoveSealingCost(
                            formValues["square_foot"],
                            1
                          )
                        )}
                      </p>
                    </>
                  )}

                  {formValues["remove_roof_sealing"] &&
                    formValues["remove_roof_object"] && <hr></hr>}
                  <p>
                    Remover o corregir objetos existentes:{" "}
                    {formValues["remove_roof_object"] ? "si" : "no"}
                  </p>
                  {formValues["remove_roof_object"] && (
                    <>
                      <p>Objetos a remover o corregir:</p>
                      {formValues["remove_roof_object"] &&
                        generateRoofObjects()}
                    </>
                  )}
                </div>
              </Cards>
            </div>
            {/* <div className="atbd-review-order__single">
              <Cards headless bodypadding={"10px"}>
                <div>
                  <Heading as="h5">Promo Codes</Heading>
                </div>
                <div className="promo-apply-form">
                  <Form.Item
                    name="promoCode"
                    label=""
                    onChange={handleChange}
                    value={formValues.promoCode ? formValues.promoCode : ""}
                  >
                    <Input placeholder="Promo Code" />
                  </Form.Item>
                  <p className="text-danger">{promoCodeError}</p>
                  <Form.Item>
                    <Button
                      htmlType="submit"
                      size="default"
                      type="success"
                      outlined
                      onClick={handlePromoSubmit}
                    >
                      Apply
                    </Button>
                  </Form.Item>
                </div>
                <br></br>
                <ul className="promo-code-list">{generatePromoCodes()}</ul>
              </Cards>
            </div> */}

            <div className="atbd-review-order__single">
              <Cards headless>
                <div>
                  <Heading as="h5">Resumen de Cotización </Heading>
                </div>
                <p>
                  Sub-Total:{" "}
                  <strong>
                    {formatter.format(calculateRoofQuoteCost(formValues))}
                  </strong>
                </p>
                {/* {formValues.promoCodes && formValues.promoCodes.length > 0 && (
                  <p className="text-danger">
                    Discount:{" "}
                    <strong>
                      -{formatter.format(generateDiscountPrice())}
                    </strong>
                  </p>
                )} */}

                <p>
                  Tax:{" "}
                  <strong>
                    {formatter.format(
                      (calculateRoofQuoteCost(formValues) -
                        generateDiscountPrice()) *
                        0.115
                    )}
                  </strong>
                </p>

                <h2>
                  Total:{" "}
                  <strong>
                    {formatter.format(
                      (calculateRoofQuoteCost(formValues) -
                        generateDiscountPrice()) *
                        1.115
                    )}
                  </strong>
                </h2>

                <p>
                  Costo por pie cuadrado:{" "}
                  <strong>
                    {formatter.format(
                      ((calculateRoofQuoteCost(formValues) -
                        generateDiscountPrice()) *
                        1.115) /
                        formValues.square_foot
                    )}
                  </strong>
                </p>
              </Cards>
            </div>

            <div className="atbd-review-order__single">
              <Cards headless bodypadding={"10px"}>
                <div>
                  <Heading as="h5">Commentarios</Heading>
                </div>
                <div className="promo-apply-form">
                  <Form.Item
                    name="comments"
                    label=""
                    onChange={handleChange}
                    value={formValues.comments ? formValues.comments : ""}
                  >
                    <Input.TextArea
                      placeholder="Escribe aquí tus comentarios"
                      rows={3}
                    />
                  </Form.Item>
                </div>
              </Cards>
            </div>
          </Cards>
        </div>
      )}
    </BasicFormWrapper>
  );
}

let mapStateToProps = (state) => ({
  roofObjects: state.roofObjects.roofObjects,
  products: state.products.products,
  // promoCodes: state.promoCodes.promoCodes,
  // loading: state.promoCodes.loading,
});

let mapDispatchToProps = (dispatch) => ({
  // fetchPromoCodes: () => dispatch(fetchPromoCodesAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuoteReview);
