import React from "react";
import { Page, Text, View, StyleSheet, Image, Font } from "@react-pdf/renderer";
import { formatter } from "../../helpers/helper";
import { getProductPrice } from "../helpers/helper";
import {
  hasSolarPanels,
  countSolarPanels,
  countInverters,
  getSolarPanel,
  getInverter,
} from "../helpers/helper";
import moment from "moment";
import "moment/locale/es";
import PoppinsRegular from "../../assets/fonts/Poppins-Regular.ttf";
import PoppinsBold from "../../assets/fonts/Poppins-Bold.ttf";
import PoppinsItalic from "../../assets/fonts/Poppins-Italic.ttf";
import ZeyadaRegular from "../../assets/fonts/Zeyada-Regular.ttf";
import { newLanguages as text } from "./lang";

Font.register({
  family: "Poppins",
  format: "truetype",
  src: PoppinsRegular,
});

Font.register({
  family: "PoppinsBold",
  format: "truetype",
  src: PoppinsBold,
});

Font.register({
  family: "PoppinsItalic",
  format: "truetype",
  src: PoppinsItalic,
});

Font.register({
  family: "Zeyada",
  format: "truetype",
  src: ZeyadaRegular,
});

const styles = StyleSheet.create({
  page: {
    // flexDirection: "row",
    backgroundColor: "#FFF",
    boxSizing: "border-box",
    fontFamily: "Poppins",
    fontSize: 10,
  },
  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  headerText: {
    fontSize: 12,
    color: "#1f497d",
    margin: "0 10",
  },
  bodyText: {
    fontSize: 10,
    color: "#343a40",
    // margin: "0 10",
  },
  secondaryHeader: {
    fontSize: 10,
    margin: "8 0",
  },
  flex: {
    display: "flex",
  },
  bold: {
    fontFamily: "PoppinsBold",
  },
  justifiedRight: {
    textAlign: "right",
  },
  orangeText: {
    color: "#E9682A",
  },
  grayText: {
    color: "#343a40",
  },
  blueText: {
    color: "#2C4596",
  },
  whiteText: {
    color: "#ffffff",
  },
  orangeBackground: {
    backgroundColor: "#E9682A",
  },
  grayBackground: {
    backgroundColor: "#343a40",
  },
  lightGrayBackground: {
    backgroundColor: "#F2F2F2",
  },
  blueBackground: {
    backgroundColor: "#2C4596",
  },
  whiteSmallText: {
    color: "#ffffff",
    fontSize: "9px",
  },

  table: {
    width: "100%",
    // borderRight: "1px solid #2C4596",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-around",
  },
  cell: {
    paddingTop: "12",
    paddingBottom: "10",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    borderLeft: "1px solid #E6E6E6",
    borderBottom: "1px solid #E6E6E6",
    width: "33.333333%",
    height: "100%",
    fontSize: "10",
    backgroundColor: "#E6E6E6",
  },
  cell100: {
    paddingTop: "12",
    paddingBottom: "10",
    paddingLeft: "8",
    paddingRight: "8",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    borderLeft: "1px solid #E6E6E6",
    borderBottom: "1px solid #E6E6E6",
    width: "100%",
    height: "100%",
    fontSize: "10",
    backgroundColor: "#E6E6E6",
  },
  cell15: {
    paddingTop: "12",
    paddingBottom: "10",
    paddingLeft: "8",
    paddingRight: "8",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    borderLeft: "1px solid #E6E6E6",
    borderBottom: "1px solid #E6E6E6",
    width: "15%",
    fontSize: "10",
    backgroundColor: "#E6E6E6",
  },
  headerCell: {
    backgroundColor: "#2C4596",
    color: "#FFFFFF",
    border: "1px solid #2C4596",
  },
  totalPrice: {
    marginTop: "8px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%",
  },
  upperCase: {
    textTransform: "uppercase",
  },
  lineText: {
    display: "flex",
    flexDirection: "row",
  },

  boxedText: {
    padding: "4px 8px 2px 8px",
    borderRadius: "3px",
    border: "1px solid #dcdcdc",
    width: "100%",
  },
  marginSpacer: {
    marginTop: "6px",
    marginBottom: "4px",
  },
});

export default function QuotePage(props) {
  const { quote, lang } = props;

  const generateDate = () => {
    let localLocale = moment();
    localLocale.locale("en");

    return localLocale.format("MM[-]DD[-]YYYY");
  };

  const generateProductRows = () => {
    let productRows = [];

    productRows = [
      ...quote.quote_products.map((qp) => {
        return (
          <View style={[styles.tableRow]} key={qp.id}>
            <Text style={[styles.cell]}>{qp.product.name}</Text>
            <Text style={[styles.cell]}>
              {" "}
              {lang === "en" && qp.product.english_description
                ? qp.product.english_description
                : qp.product.description}
            </Text>
            <Text style={[styles.cell15]}>{qp.quantity}</Text>
            <Text style={[styles.cell]}>
              {getProductPrice(
                qp.product,
                qp.quantity,
                quote.quote_products,
                quote.finance_method
              ) > 0
                ? formatter.format(
                    (getProductPrice(
                      qp.product,
                      qp.quantity,
                      quote.quote_products,
                      quote.finance_method
                    ) *
                      parseInt(qp.quantity, 10)) /
                      100
                  )
                : text[lang].includedPrice}
            </Text>
          </View>
        );
      }),
    ];

    // If finance method is united
    // Add united row
    if (quote.finance_method === "United") {
      productRows.push(
        <View style={[styles.tableRow]}>
          <Text style={[styles.cell]}>United</Text>
          <Text style={[styles.cell]}>{text[lang].unitedDescription}</Text>
          <Text style={[styles.cell15]}> </Text>
          <Text style={[styles.cell]}>
            {formatter.format(generateUnitedCost())}
          </Text>
        </View>
      );
    }

    if (hasSolarPanels(quote)) {
      productRows.push(
        <View style={[styles.tableRow]}>
          <Text style={[styles.cell]}>Unirac</Text>
          <Text style={[styles.cell]}>{text[lang].uniracDescription}</Text>
          <Text style={[styles.cell15]}> </Text>
          <Text style={[styles.cell]}>{text[lang].includedPrice}</Text>
        </View>
      );
    }

    return productRows;
  };

  const generatePromoCodes = () => {
    return quote.quote_promo_codes.map((p) => {
      return (
        <View style={[styles.tableRow]} key={p.id}>
          <Text style={[styles.cell]}>Promo {p.promo_code.code}</Text>
          <Text style={[styles.cell]}>{p.promo_code.name}</Text>
          <Text style={[styles.cell15]}></Text>
          <Text style={[styles.cell]}>
            -{formatter.format(parseInt(p.promo_code.discount, 10) / 100)}
          </Text>
        </View>
      );
    });
  };

  // Get united cost (10%)
  const generateUnitedCost = () => {
    return generateFinalPrice() - generateFinalPrice() / 1.1;
  };

  const generateDiscountPrice = () => {
    if (quote.quote_promo_codes && quote.quote_promo_codes.length > 0) {
      let result = quote.quote_promo_codes.reduce((sumDiscount, p) => {
        return (sumDiscount += parseInt(p.promo_code.discount, 10));
      }, 0);

      return result / 100;
    }

    return 0;
  };

  const generateFinalPrice = () => {
    if (quote.quote_products) {
      let result = quote.quote_products.reduce((sumPrice, qp) => {
        return (sumPrice +=
          getProductPrice(
            qp.product,
            qp.quantity,
            quote.quote_products,
            quote.finance_method
          ) * parseInt(qp.quantity, 10));
      }, 0);

      // If financed through United, add 10% cost
      if (quote.finance_method === "United") {
        result += result * 0.1;
      }

      return result / 100 - generateDiscountPrice();
    }

    return 0;
  };

  return (
    <Page size="A4" style={styles.page}>
      {/* Page Container */}
      <View
        style={{
          width: "100%",
          display: "flex",
          border: "1px solid #ccc",
          padding: "48 0 0 0",
          boxSizing: "border-box",
          justifyContent: "space-between",
          alignItems: "center",
          height: "100%",
        }}
      >
        {/* Header */}
        <View
          style={[
            styles.headerText,
            styles.upperCase,
            {
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            },
          ]}
        >
          <Text
            style={[
              styles.blueText,
              styles.bold,
              { fontSize: 28, textAlign: "center" },
            ]}
          >
            {text[lang].quotePageTitleBlue}{" "}
          </Text>
          <Text
            style={[
              styles.orangeText,
              styles.bold,
              {
                fontSize: 28,
                textAlign: "center",
              },
            ]}
          >
            {text[lang].quotePageTitleOrange}
          </Text>
        </View>

        {/* Page Content */}

        <View
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: "100%",
            padding: "48px",
          }}
        >
          {/* Bill To, Payment Method, and Date */}
          <View
            style={[
              {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                height: "100px",
                textAlign: "left",
                marginBottom: "24px",
              },
              styles.marginSpacer,
            ]}
          >
            {/* Bill To */}
            <View
              style={[
                {
                  display: "flex",
                  width: "60%",
                  flexDirection: "column",
                  alignItems: "center",
                },
                styles.bodyText,
              ]}
            >
              <Text
                style={[
                  {
                    width: "100%",
                    fontSize: "14px",
                  },
                  styles.bold,
                ]}
              >
                {text[lang].quotePageForHeader}
              </Text>
              {/* Name */}
              <Text style={[{ width: "100%", fontSize: "12px" }]}>
                {quote.customer_name}
              </Text>
              {/* Direction */}
              <Text style={[[{ width: "100%" }]]}>{quote.address_line1}</Text>
              {/* Municipality */}
              <Text style={[[{ width: "100%" }]]}>{quote.state}, PR</Text>
            </View>

            {/* Metodo y invoice date  */}
            <View
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                padding: "0px 0px 0px 20px",
                backgroundColor: "#FFF",
                width: "38%",
              }}
            >
              {/* Metodo de Pago  */}
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginBottom: "20px",
                }}
              >
                <Image
                  style={{
                    height: "20px",
                    width: "24px",
                    display: "flex",
                    flexDirection: "row",
                    marginRight: "8px",
                  }}
                  src="/assets/commercial quotes images/credit-card-icon-2.png"
                />
                <View
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Text>{text[lang].quotePageFinanceMethod}</Text>
                  <Text style={[styles.bold]}>{quote.finance_method}</Text>
                </View>
              </View>
              {/* Invoice Date  */}
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Image
                  style={{
                    height: "20px",
                    width: "24px",
                    display: "flex",
                    flexDirection: "row",
                    marginRight: "8px",
                  }}
                  src="/assets/commercial quotes images/calendar-alt-icon-2.png"
                />
                <View
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Text>{text[lang].quotePageDate}</Text>
                  <Text style={[styles.bold]}>{generateDate()}</Text>
                </View>
              </View>
            </View>
          </View>

          {/* Tabla de Productos*/}
          <View
            style={[
              {
                width: "100%",
                display: "flex",
                flexDirection: "column",
                marginBottom: "24px",
              },
              styles.marginSpacer,
            ]}
          >
            {/* Table */}
            <View style={[{}, styles.table]}>
              <View style={[styles.tableRow]}>
                <Text style={[styles.cell, styles.headerCell, styles.bold]}>
                  {text[lang].quotePageProductColumn}
                </Text>
                <Text style={[styles.cell, styles.headerCell, styles.bold]}>
                  {text[lang].quotePageDescriptionColumn}
                </Text>
                <Text style={[styles.cell15, styles.headerCell, styles.bold]}>
                  {text[lang].quotePageAmountColumn}
                </Text>
                <Text style={[styles.cell, styles.headerCell, styles.bold]}>
                  {text[lang].quotePagePriceColumn}
                </Text>
              </View>
              {generateProductRows()}
              {generatePromoCodes()}
            </View>
            {/* Total y Subtotal */}
            <View
              style={[
                {
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                },
                styles.lightGrayBackground,
              ]}
            >
              <View
                style={[
                  {
                    width: "48%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  },
                  styles.marginSpacer,
                ]}
              >
                <Text
                  style={[
                    styles.blueText,
                    styles.bold,
                    {
                      fontSize: "14px",
                    },
                  ]}
                >
                  {text[lang].quotePageThankYou}
                </Text>
              </View>
              {/* Total y Subtotal */}
              <View
                style={[
                  {
                    width: "48%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignContent: "flex-end",
                  },
                  styles.marginSpacer,
                ]}
              >
                {/* Subtotal */}
                {/* <View
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    width: "100%",
                    padding: "10px 16px",
                  }}
                >
                  <Text style={[{ textAlign: "right" }]}>Subtotal:</Text>
                  <Text
                    style={[
                      {
                        fontSize: "12px",
                        textAlign: "right",
                      },
                      styles.grayText,
                    ]}
                  >
                    {formatter.format(generateFinalPrice())}
                  </Text>
                </View> */}

                {/* Total */}
                <View
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    width: "100%",
                    padding: "10px 16px",
                  }}
                >
                  <Text style={[{ textAlign: "right" }]}>Total:</Text>
                  <Text
                    style={[
                      {
                        fontSize: "24px",
                        textAlign: "right",
                      },
                      styles.blueText,
                    ]}
                  >
                    {formatter.format(generateFinalPrice())}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          {/* Section - DESCRIPCIÓN EQUIPO & PERMISOS */}
          <View
            style={[
              { width: "100%", display: "flex", padding: "12px" },
              styles.lightGrayBackground,
            ]}
          >
            <View
              style={[
                styles.secondaryHeader,
                styles.grayText,
                styles.upperCase,
                styles.bold,
                { width: "100%" },
              ]}
            >
              <Text>{text[lang].descriptionHeader}</Text>
            </View>

            {/* Plan de Pago Content */}
            <View style={[styles.lineText, { marginBottom: 16 }]}>
              {/* <Text style={[styles.orangeText, styles.bold]}>Power Solar, LLC.</Text>  */}
              {!hasSolarPanels(quote) && (
                <Text>{text[lang].quoteDescription()} </Text>
              )}
              {hasSolarPanels(quote) && (
                <Text>
                  {text[lang].quoteDescription(
                    true,
                    countInverters(quote),
                    getInverter(quote).name,
                    countSolarPanels(quote),
                    getSolarPanel(quote).name
                  )}
                </Text>
              )}
            </View>
          </View>

          {/* Additional Comments */}
          {quote && quote.comments && quote.comments.length > 0 && (
            <View
              style={[
                {
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-start",
                  minHeight: "40px",
                },
                styles.marginSpacer,
              ]}
            >
              <Text style={[styles.orangeText, styles.bold]}>
                {text[lang].quotePageCommentsTitle}:
              </Text>
              <Text>{quote.comments}</Text>
            </View>
          )}
        </View>

        {/* Footer */}
        <Image
          style={{
            width: "100%",
            display: "flex",
            alignSelf: "center",
          }}
          src={`/assets/bottom-triangles-border.png`}
        />
      </View>
    </Page>
  );
}
