import React, { useEffect } from "react";
import { Row, Col, Form, Input, Select } from "antd";
import { Button } from "../../components/buttons/buttons";
import FeatherIcon from "feather-icons-react";

const { Option } = Select;
const FilterRow = (props) => {
  const { setFilteredPromoCodes, promoCodes } = props;

  const [form] = Form.useForm();

  const handleFilter = () => {
    let filteredPromoCodes = [...promoCodes];

    if (form.getFieldValue("name")) {
      filteredPromoCodes = filteredPromoCodes.filter((p) =>
        p.name.toLowerCase().includes(form.getFieldValue("name").toLowerCase())
      );
    }

    if (form.getFieldValue("code")) {
      filteredPromoCodes = filteredPromoCodes.filter((p) =>
        p.code.toLowerCase().includes(form.getFieldValue("code").toLowerCase())
      );
    }

    if (form.getFieldValue("is_enabled") !== "") {
      filteredPromoCodes = filteredPromoCodes.filter(
        (p) => p.is_enabled === form.getFieldValue("is_enabled")
      );
    }

    setFilteredPromoCodes([...filteredPromoCodes]);
  };

  useEffect(() => {
    handleFilter();
    // eslint-disable-next-line
  }, [promoCodes]);

  const handleClear = () => {
    setFilteredPromoCodes([...promoCodes]);
    form.resetFields();
    handleFilter();
  };

  return (
    <Form
      name="promoCodes-filter"
      form={form}
      style={{ paddingBottom: "15px", borderBottom: "1px solid #d6d6d6" }}
      initialValues={{ is_enabled: true }}
    >
      <Row gutter={30}>
        <Col sm={8} xs={24} className="mb-25">
          <Form.Item name="name">
            <Input placeholder="Name" />
          </Form.Item>
        </Col>
        <Col sm={8} xs={24} className="mb-25">
          <Form.Item name="code">
            <Input placeholder="Code" />
          </Form.Item>
        </Col>
        <Col sm={8} xs={24} className="mb-25">
          <Form.Item name="is_enabled">
            <Select style={{ minWidth: "300px" }}>
              <Option value={true}>Enabled</Option>
              <Option value="">All</Option>
              <Option value={false}>Disabled</Option>
            </Select>
          </Form.Item>
        </Col>
        {/* <Col sm={12} xs={24} className="mb-25">
          <Form.Item name="phone">
            <Input placeholder="Customer Phone" />
          </Form.Item>
        </Col> */}
      </Row>
      <Row></Row>
      <Row>
        <Col sm={12} xs={24}>
          <div className="sDash_form-action">
            <Button
              type="primary"
              style={{ marginRight: "15px" }}
              onClick={handleFilter}
            >
              <FeatherIcon icon="search" size={14} />
              Apply
            </Button>
            <Button type="light" onClick={handleClear}>
              <FeatherIcon icon="x" size={14} />
              Clear
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default FilterRow;
