import React from "react";
import FeatherIcon from "feather-icons-react";
import roles, { adminRoles } from "./data/roles";

const menuItems = [
  {
    label: "Cotizaciones",
    key: "sub1",
    icon: <FeatherIcon icon="file-text" />,
    roles: [...roles],
    children: [
      {
        label: "Lista de Cotizaciones",
        key: "/app/quotations",
      },
    ],
  },
  {
    label: "Crear Cotizaciones",
    key: "sub2",
    icon: <FeatherIcon icon="plus" />,
    roles: [...roles],
    children: [
      {
        label: "EcoFlow",
        key: "/app/eco-flow-quotations/new",
      },
      {
        label: "Residencial",
        key: "/app/quotations/new",
      },

      {
        label: "Comercial",
        key: "/app/commercial-quotations/new",
      },
      {
        label: "Sellado de Techo",
        key: "/app/roof-quotations/new",
      },
    ],
  },
  {
    label: "Vivienda",
    key: "sub3",
    icon: <FeatherIcon icon="home" />,
    roles: [...adminRoles],
    children: [
      {
        label: "Sunnova Loan / Cash",
        key: "/app/vivienda-estimates/new",
      },
      {
        label: "Sunnova Lease / Incentivo",
        key: "/app/sunnova-incentives/new",
      },
      {
        label: "Vivienda Energía 2",
        key: "/app/vivienda-estimates-energy-2/new",
      },
    ],
  },
  {
    label: "Usuarios",
    key: "sub4",
    icon: <FeatherIcon icon="user" />,
    roles: [...adminRoles],
    children: [
      {
        label: "Lista de Usuarios",
        key: "/app/users",
      },
    ],
  },
  {
    label: "Productos",
    key: "sub5",
    icon: <FeatherIcon icon="shopping-cart" />,
    roles: [adminRoles[1]],
    children: [
      {
        label: "Lista de Productos",
        key: "/app/products",
      },
    ],
  },
  {
    label: "Códigos Promocionales",
    key: "sub6",
    icon: <FeatherIcon icon="tag" />,
    roles: [...adminRoles],
    children: [
      {
        label: "Lista de Códigos Promocionales",
        key: "/app/promo-codes",
      },
    ],
  },
];

export default menuItems;
