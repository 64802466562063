import React, { useState, useCallback } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
// eslint-disable-next-line import/no-extraneous-dependencies
import { AuthWrapper } from "./style";
import { registerAction } from "../../redux/actions/authActions";
import AuthLayout from "../../components/layouts/AuthLayout";
import RegisterForm from "../../components/forms/RegisterForm/RegisterForm";

const SignIn = (props) => {
  const { register, loading } = props;

  const [registerErrors, setRegisterErrors] = useState({});
  const [showDisabledCard, setShowDisabledCard] = useState(false);

  const handleSubmit = useCallback(
    (values) => {
      setRegisterErrors({})
      setShowDisabledCard(false);
      register(values).then((result) => {
        if (result && result.id) {
          setShowDisabledCard(true);
        } else if (result) {
          setRegisterErrors({ ...result });
        } else {
          setRegisterErrors({ message: "Something went wrong" });
        }
      });
    },
    [register]
  );

  return (
    <AuthLayout>
      <AuthWrapper>
        <div className="auth-contents">
          <RegisterForm
            handleSubmit={handleSubmit}
            loading={loading}
            registerErrors={registerErrors}
            showDisabledCard={showDisabledCard}
          />
          <hr />
          <p className="auth-notice">
            Already have an account? <NavLink to="/sign-in">Sign in</NavLink>
          </p>
        </div>
      </AuthWrapper>
    </AuthLayout>
  );
};

const mapStateToProps = (state) => ({
  loading: state.auth.loading,
});

const mapDispatchToProps = (dispatch) => ({
  register: (formValues) => dispatch(registerAction(formValues)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
