import React from "react";
import { Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
// import text from "./lang";
import {
  calculateRoofQuoteCost,
  generateDiscountPrice,
} from "../helpers/helper";
import { formatter } from "../../helpers/helper";

const styles = StyleSheet.create({
  page: {
    // flexDirection: "row",
    backgroundColor: "#FFF",
    boxSizing: "border-box",
    fontSize: 10,
    padding: 0,
    fontFamily: "Poppins",
  },
  header: {
    width: "100%",
    display: "flex",
    alignSelf: "center",
    backgroundColor: "#1D4091",
    color: "#fff",
    padding: "16 16 8 16",
    marginBottom: 16,
    borderBottom: "solid 3px #0E9CD5",
  },
  headerText: {
    fontSize: 14,
    color: "#1f497d",
    margin: "0 10",
  },
  bodyText: {
    fontSize: "10px",
    color: "#343a40",
    margin: "0",
    lineHeight: "1.5px",
  },
  secondaryHeader: {
    fontSize: 16,
    margin: "8 0",
  },
  flex: {
    display: "flex",
  },
  bold: {
    fontFamily: "PoppinsBold",
  },
  italic: {
    fontFamily: "PoppinsItalic",
  },
  justifiedRight: {
    textAlign: "right",
  },
  orangeText: {
    color: "#E9682A",
  },
  grayText: {
    color: "#343a40",
  },

  blueText: {
    color: "#2C4596",
  },

  table: {
    width: "100%",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-around",
  },
  cell: {
    paddingTop: "5",
    paddingBottom: "3",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    border: "1 solid #ccc",
    width: "33.333333%",
    height: "100%",
    fontSize: "9",
  },
  headerCell: {
    backgroundColor: "#E9682A",
    color: "#FFFFFF",
  },
  totalPrice: {
    marginTop: "8px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%",
  },
  upperCase: {
    textTransform: "uppercase",
  },
  lineText: {
    display: "flex",
    flexDirection: "row",
  },
});

function AgreementPageOne(props) {
  const { quote } = props;
  return (
    <Page size="A4" style={styles.page}>
      <View
        style={{
          width: "100%",
          display: "flex",
          boxSizing: "border-box",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        {/* Header */}
        <View
          style={[
            {
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "row",
              alignItems: "center",
              backgroundColor: "#1D4091",
              color: "#fff",
              padding: "20 16 20 16",
              borderBottom: "4px solid #0E9CD5",
              marginBottom: 0,
              fontSize: 14,
              height: "72px",
            },
            styles.upperCase,
          ]}
        >
          {/* Page title */}
          <Image
            style={{
              height: "28px",
            }}
            src={`/assets/commercial quotes images/powersolar-logo-white.png`}
          />
        </View>

        {/* Page Content */}
        <View
          style={[
            {
              padding: 48,
              display: "flex",
              flexDirection: "column",
              height: "100%",
            },
            styles.bodyText,
          ]}
        >
          <Text style={[{ marginBottom: 16 }]}>
            <Text style={[{}, styles.bold]}>DE UNA PARTE:</Text>{" "}
            <Text style={[{}, styles.italic]}>Power Solar, LLC </Text>(en
            adelante referido como “VENDEDOR”), número de identificación
            patronal 660-89-8013, una corporación organizada bajo las leyes de
            Puerto Rico, con oficinas en San Juan, Puerto Rico. Y, DE OTRA PARTE
            (en adelante referido como “COMPRADOR”)
          </Text>
          {/* Nombre de Cliente */}
          <View
            style={[
              {
                width: "100%",
                display: "flex",
                flexDirection: "row",
                marginBottom: 12,
              },
            ]}
          >
            <Text>Nombre del cliente (Dueño): {"  "}</Text>
            <View style={[{ borderBottom: "1px solid black" }, styles.bold]}>
              <Text>
                {"   "}
                {quote.client_name}
                {"   "}
              </Text>
            </View>
          </View>

          {/* Direccion de la edificacion */}
          <View
            style={[
              {
                width: "100%",
                display: "flex",
                flexDirection: "row",
                marginBottom: 12,
              },
            ]}
          >
            <Text>Dirección de la edificación: {"  "}</Text>
            <View style={[{ borderBottom: "1px solid black" }, styles.bold]}>
              <Text>
                {"   "}
                {quote.address_line1 +
                  ", " +
                  quote.municipality +
                  ", " +
                  quote.state}
                {"   "}
              </Text>
            </View>
          </View>

          {/* Uso de la Edificación */}
          <View
            style={[
              {
                width: "100%",
                display: "flex",
                flexDirection: "row",
                marginBottom: 12,
              },
            ]}
          >
            <Text>Uso de la Edificación: {"  "}</Text>
            <View style={[{ borderBottom: "1px solid black" }, styles.bold]}>
              <Text>{"                                    "}</Text>
            </View>
          </View>

          {/* Área sq-ft (en Pies cuadrados) de techo:  */}
          <View
            style={[
              {
                width: "100%",
                display: "flex",
                flexDirection: "row",
                marginBottom: 12,
              },
            ]}
          >
            <Text>Área sq-ft (en Pies cuadrados) de techo: {"  "}</Text>
            <View style={[{ borderBottom: "1px solid black" }, styles.bold]}>
              <Text>
                {"  "}
                {quote.square_foot}
                {"  "}
              </Text>
            </View>
          </View>

          {/* Tipo de Techo */}
          <View
            style={[
              {
                width: "100%",
                display: "flex",
                flexDirection: "row",
                marginBottom: 12,
              },
            ]}
          >
            <Text>Tipo de Techo: {"  "}</Text>
            <View style={[{ borderBottom: "1px solid black" }, styles.bold]}>
              <Text>{"                                    "}</Text>
            </View>
          </View>

          {/* Sistema de Membrana ofrecido: */}
          <View
            style={[
              {
                width: "100%",
                display: "flex",
                flexDirection: "row",
                marginBottom: 12,
              },
            ]}
          >
            <Text>Sistema de Membrana ofrecido: {"  "}</Text>
            <View style={[{ borderBottom: "1px solid black" }, styles.bold]}>
              <Text>
                {"   "}Esterdan R-36 ____ // Glasdan AL 80-4 ____{"   "}
              </Text>
            </View>
          </View>

          {/* Cantidad de Membranas */}
          <View
            style={[
              {
                width: "100%",
                display: "flex",
                flexDirection: "row",
                marginBottom: 36,
              },
            ]}
          >
            <Text>
              {quote.membrane} membrana{quote.membrane > 1 ? "s" : ""} (10 años
              garantía)
            </Text>
          </View>

          {/* Costo del Sistema de sellado*/}
          <View
            style={[
              {
                width: "100%",
                display: "flex",
                flexDirection: "row",
                marginBottom: 36,
              },
            ]}
          >
            <Text>Costo del Sistema de sellado: {"  "}</Text>
            <View style={[{ borderBottom: "1px solid black" }, styles.bold]}>
              <Text>
                {"  "}
                {formatter.format(
                  (calculateRoofQuoteCost(quote) * 1.115) - generateDiscountPrice(quote)
                )}
                {"  "}
              </Text>
            </View>
          </View>

          <View
            style={[
              {
                display: "flex",
                flexDirection: "row",
                marginBottom: 16,
              },
            ]}
          >
            <Text style={{ paddingRight: 12, width: "30px" }}>I. {"  "}</Text>
            <Text
              style={{
                width: "calc(100% - 30px)",
                textAlign: "justify",
                textJustify: "inter-word",
              }}
            >
              EXPONEN que, al <Text style={[{}, styles.italic]}>COMPRADOR</Text>{" "}
              a través de este acuerdo, le garantiza al propietario de la
              edificación arriba mencionada (en adelante el{" "}
              <Text style={[{}, styles.italic]}>DUEÑO</Text>
              ), que sistema de impermeabilización se mantendrá impermeable
              durante un diez (10) años para 2 membranas, por cada área, a
              partir de la fecha de terminación de la instalación. En caso de
              desperfectos en la instalación, Power Solar, LLC, costeará los
              gastos incurridos en corregir los mismos, sujeto a las cláusulas y
              restricciones de este documento. Esta garantía está sujeta a los
              términos, condiciones y limitaciones descritas en este acuerdo.
            </Text>
          </View>

          <View
            style={[
              {
                width: "100%",
                display: "flex",
                flexDirection: "row",
                marginBottom: 16,
              },
            ]}
          >
            <Text style={{ paddingRight: 12, width: "30px" }}>II. {"  "}</Text>
            <Text
              style={{
                width: "calc(100% - 30px)",
                wordBreak: "breakWord",
                textAlign: "justify",
                textJustify: "inter-word",
              }}
            >
              Power Solar, LLC, por conducto del manufacturero, garantiza además
              que los productos utilizados están libres de defectos de
              fabricación y que estos seguirán siendo efectivos en condiciones
              normales de uso previsto durante 10 años a partir de la fecha de
              finalización de la aplicación adecuada de los mismos. A su vez,
              esta garantía en los productos por conducto del manufacturero
              disminuye a razón del 10% anual. En caso de que surjan tales
              defectos, Power Solar, LLC, por conducto del manufacturero,
              suministrará y aplicará los materiales necesarios para reparar el
              área afectada por el defecto
            </Text>
          </View>
        </View>
        {/* Power Solar Contact Info */}
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            backgroundColor: "#F2F2F2",
            padding: "16px",
            fontSize: 10,
            color: "#2C4596",
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Image
              style={{
                height: "14px",
                display: "flex",
                flexDirection: "row",
                marginRight: 4,
                padding: 2,
              }}
              src="/assets/icons8-phone-64-blue.png"
            />
            <Text style={[styles.whiteSmallText]}>787.331.100</Text>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Image
              style={{
                height: "14px",
                display: "flex",
                flexDirection: "row",
                marginRight: 4,
                padding: 2,
              }}
              src="/assets/icons8-website-64-blue.png"
            />
            <Text style={[styles.whiteSmallText]}>powersolarpr.com</Text>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Image
              style={{
                height: "14px",
                width: "auto",
                display: "flex",
                flexDirection: "row",
                marginRight: 4,
                padding: 2,
              }}
              src="/assets/icons8-facebook-64-blue.png"
            />
            <Text style={[styles.whiteSmallText]}>PowerSolarPR</Text>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Image
              style={{
                height: "14px",
                display: "flex",
                flexDirection: "row",
                marginRight: 4,
                padding: 2,
              }}
              src="/assets/icons8-location-64-blue.png"
            />
            <Text style={[styles.whiteSmallText]}>
              279 calle César L. Gónzalez • San Juan, P.R. 00918
            </Text>
          </View>
        </View>
      </View>
    </Page>
  );
}

export default AgreementPageOne;
