import React, { useState, useEffect } from "react";
import { BasicFormWrapper } from "../../../../containers/styled";
import Heading from "../../../heading/heading";
import { Cards } from "../../../cards/frame/cards-frame";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { connect } from "react-redux";
import { formatter } from "../../../../helpers/helper";
import { getProductPrice } from "../../../helpers/helper";
import { Form, Input, Col, Skeleton } from "antd";
import { Button } from "../../../buttons/buttons";
import { fetchPromoCodesAction } from "../../../../redux/actions/promoCodeActions";
import moment from "moment";

function QuoteReview(props) {
  const { form, products, fetchPromoCodes, promoCodes, loading, setStep } =
    props;

  useEffect(() => {
    if (!promoCodes || (promoCodes && promoCodes.length === 0)) {
      fetchPromoCodes();
    }
    // eslint-disable-next-line
  }, []);

  const [formValues, setFormValues] = useState({ promoCodes: [] });
  const [promoCodeError, setPromoCodeError] = useState("");

  useEffect(() => {
    setFormValues({ ...formValues, ...form.getFieldsValue(true) });
    // eslint-disable-next-line
  }, [form]);

  useEffect(() => {
    form.setFieldsValue({
      ...form.getFieldsValue(true),
      promoCodes: formValues.promoCodes,
      comments: formValues.comments,
    });
    // eslint-disable-next-line
  }, [formValues.promoCodes, formValues.comments]);

  const hasFormValues =
    formValues["solarPanels"] ||
    formValues["inverters"] ||
    formValues["batteries"];

  const generateProducts = () => {
    if (hasFormValues) {
      let formProducts = [];
      if (formValues["solarPanels"]) {
        formProducts = [...formProducts, ...formValues["solarPanels"]];
      }
      if (formValues["inverters"]) {
        formProducts = [...formProducts, ...formValues["inverters"]];
      }
      if (formValues["batteries"]) {
        formProducts = [...formProducts, ...formValues["batteries"]];
      }
      return formProducts.map((p) => {
        let data = products.find((product) => product.id === p.id);
        return (
          <div>
            <hr style={{ borderTop: "1px solid #eeeeee" }} />
            <h3 className="orange-text">{data.name}</h3>
            {/* <p>{data.description}</p> */}
            <p>
              {/* <strong>Price:</strong>{" "} */}
              {formatter.format(
                getProductPrice(
                  data,
                  p.quantity,
                  formProducts.map((fp) => ({
                    ...fp,
                    product: products.find((p1) => p1.id === fp.id),
                  })),
                  formValues["finance_method"]
                ) / 100
              )}
            </p>
            <li>
              <strong>Quantity: </strong>
              {p.quantity}
            </li>
            <li>
              <strong>Total Price:</strong>{" "}
              {formatter.format(
                (getProductPrice(
                  data,
                  p.quantity,
                  formProducts.map((fp) => ({
                    ...fp,
                    product: products.find((p1) => p1.id === fp.id),
                  })),
                  formValues["finance_method"]
                ) *
                  parseInt(p.quantity, 10)) /
                  100
              )}
            </li>
          </div>
        );
      });
    }

    return [];
  };

  const handleRemovePromoCode = (promoCodeId) => {
    setPromoCodeError("");

    setFormValues({
      ...formValues,
      promoCodes: [
        ...formValues.promoCodes.filter((p) => p.id !== promoCodeId),
      ],
    });
  };

  const generatePromoCodes = () => {
    return formValues.promoCodes.map((p) => (
      <li className="promo-code-item">
        <strong
          className="text-info"
          style={{
            paddingRight: "8px",
            maxWidth: "70%",
            wordBreak: "break-word",
          }}
        >
          {" "}
          {p.code} ({formatter.format(p.discount / 100)} OFF)
        </strong>{" "}
        applied{" "}
        <FeatherIcon
          style={{ paddingLeft: "8px", width: "16px !important" }}
          className="text-danger"
          icon="trash-2"
          onClick={() => handleRemovePromoCode(p.id)}
        />
      </li>
    ));
  };

  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.id]: e.target.value });
  };

  const handlePromoSubmit = (e) => {
    e.preventDefault();
    setPromoCodeError("");

    // Update this to filter promo codes based on current date and if the promo code is enabled
    let filteredPromoCodes = promoCodes.filter((p) => {
      return (
        p.is_enabled &&
        moment()
          .startOf("day")
          .diff(moment(p.valid_from).startOf("day"), "days") >= 0 &&
        moment()
          .startOf("day")
          .diff(moment(p.valid_until).startOf("day"), "days") <= 0
      );
    });

    if (formValues.promoCode) {
      let promoCode = filteredPromoCodes.find(
        (p) => p.code.toUpperCase() === formValues.promoCode.toUpperCase()
      );

      if (promoCode) {
        if (!formValues.promoCodes.includes(promoCode)) {
          setFormValues({
            ...formValues,
            promoCodes: [...formValues.promoCodes, promoCode],
          });
        } else {
          setPromoCodeError(`Promo Code ${promoCode.code} already used.`);
        }
      } else {
        setPromoCodeError(`Promo Code ${formValues.promoCode} not found.`);
      }
    }
  };

  const generateDiscountPrice = () => {
    if (formValues.promoCodes && formValues.promoCodes.length > 0) {
      let result = formValues.promoCodes.reduce((sumDiscount, p) => {
        return (sumDiscount += parseInt(p.discount, 10));
      }, 0);

      return result / 100;
    }

    return 0;
  };

  // Get united cost (10%)
  const generateUnitedCost = () => {
    return generateFinalPrice() - generateFinalPrice() / 1.1;
  };

  const generateFinalPrice = () => {
    if (hasFormValues) {
      let formProducts = [];
      if (formValues["solarPanels"]) {
        formProducts = [...formProducts, ...formValues["solarPanels"]];
      }
      if (formValues["inverters"]) {
        formProducts = [...formProducts, ...formValues["inverters"]];
      }
      if (formValues["batteries"]) {
        formProducts = [...formProducts, ...formValues["batteries"]];
      }
      let result = formProducts.reduce((sumPrice, p) => {
        let data = products.find((product) => product.id === p.id);
        return (sumPrice +=
          getProductPrice(
            data,
            p.quantity,
            formProducts.map((fp) => ({
              ...fp,
              product: products.find((p1) => p1.id === fp.id),
            })),
            formValues["finance_method"]
          ) * parseInt(p.quantity, 10));
      }, 0);

      // If financed through United, add 10% cost
      if (formValues["finance_method"] === "United") {
        result += result * 0.1;
      }

      return result / 100;
    }

    return 0;
  };

  return (
    <BasicFormWrapper style={{ width: "100%" }}>
      {loading && (
        <Col sm={24} xs={24}>
          <Cards headless>
            <Skeleton paragraph={{ rows: 20 }} active />
          </Cards>
        </Col>
      )}
      {!loading && (
        <div className="atbd-review-order" style={{ width: "100%" }}>
          <Heading as="h4">4. Review And Confirm Quotation</Heading>
          <Cards
            bodyStyle={{ backgroundColor: "#F8F9FB", borderRadius: 10 }}
            headless
          >
            <div className="atbd-review-order__single">
              <Cards headless>
                <div className="atbd-review-order__shippingTitle">
                  <Heading as="h5">
                    Customer Information
                    <Link to="#" onClick={() => setStep(0)}>
                      <FeatherIcon icon="edit" />
                      Edit
                    </Link>
                  </Heading>
                </div>
                <article className="atbd-review-order__shippingInfo">
                  <p>Customer Name: {formValues["customer_name"]}</p>
                  <p>Address Line 1: {formValues["address_line1"]}</p>
                  {formValues["address_line2"] && (
                    <p>Address Line 2: {formValues["address_line2"]}</p>
                  )}
                  <p>City: {formValues["state"]}</p>
                  <p>Country: {formValues["country"]}</p>
                  {formValues["luma_monthly_bill"] && (
                    <p>LUMA Monthly Bill: ${formValues["luma_monthly_bill"]}</p>
                  )}
                </article>
              </Cards>
            </div>
            <div className="atbd-review-order__single">
              <Cards headless>
                <div className="atbd-review-order__shippingTitle">
                  <Heading as="h5">
                    Finance Method
                    <Link to="#" onClick={() => setStep(1)}>
                      <FeatherIcon icon="edit" />
                      Edit
                    </Link>
                  </Heading>
                </div>
                <p>
                  {formValues["finance_method"]}{" "}
                  {formValues["finance_method"] === "United"
                    ? `(+${formatter.format(generateUnitedCost())})`
                    : ""}
                </p>
              </Cards>
            </div>
            <div className="atbd-review-order__single">
              <Cards headless>
                <div className="atbd-review-order__shippingTitle">
                  <Heading as="h5">
                    Products
                    <Link to="#" onClick={() => setStep(2)}>
                      <FeatherIcon icon="edit" />
                      Edit
                    </Link>
                  </Heading>
                </div>
                {generateProducts()}
              </Cards>
            </div>
            <div className="atbd-review-order__single">
              <Cards headless bodypadding={"10px"}>
                <div>
                  <Heading as="h5">Promo Codes</Heading>
                </div>
                <div className="promo-apply-form">
                  <Form.Item
                    name="promoCode"
                    label=""
                    onChange={handleChange}
                    value={formValues.promoCode ? formValues.promoCode : ""}
                  >
                    <Input placeholder="Promo Code" />
                  </Form.Item>
                  <p className="text-danger">{promoCodeError}</p>
                  <Form.Item>
                    <Button
                      htmlType="submit"
                      size="default"
                      type="success"
                      outlined
                      onClick={handlePromoSubmit}
                    >
                      Apply
                    </Button>
                  </Form.Item>
                </div>
                <br></br>
                <ul className="promo-code-list">{generatePromoCodes()}</ul>
              </Cards>
            </div>

            <div className="atbd-review-order__single">
              <Cards headless>
                <div>
                  <Heading as="h5">Quote Summary </Heading>
                </div>
                <p>
                  Sub-Total:{" "}
                  <strong>{formatter.format(generateFinalPrice())}</strong>
                </p>
                {formValues.promoCodes && formValues.promoCodes.length > 0 && (
                  <p className="text-danger">
                    Discount:{" "}
                    <strong>
                      -{formatter.format(generateDiscountPrice())}
                    </strong>
                  </p>
                )}

                <hr></hr>
                <h2>
                  Total:{" "}
                  <strong>
                    {formatter.format(
                      generateFinalPrice() - generateDiscountPrice()
                    )}
                  </strong>
                </h2>
              </Cards>
            </div>

            <div className="atbd-review-order__single">
              <Cards headless bodypadding={"10px"}>
                <div>
                  <Heading as="h5">Comments</Heading>
                </div>
                <div className="promo-apply-form">
                  <Form.Item
                    name="comments"
                    label=""
                    onChange={handleChange}
                    value={formValues.comments ? formValues.comments : ""}
                  >
                    <Input.TextArea
                      placeholder="Add any comments here"
                      rows={3}
                    />
                  </Form.Item>
                </div>
              </Cards>
            </div>
          </Cards>
        </div>
      )}
    </BasicFormWrapper>
  );
}

let mapStateToProps = (state) => ({
  products: state.products.products,
  promoCodes: state.promoCodes.promoCodes,
  loading: state.promoCodes.loading,
});

let mapDispatchToProps = (dispatch) => ({
  fetchPromoCodes: () => dispatch(fetchPromoCodesAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuoteReview);
