import React, { useState } from "react";
import PromoCodeCard from "../../components/PromoCodeCard/PromoCodeCard";
import { Row, Col, Pagination } from "antd";

function PromoCodesList(props) {
  const {
    promoCodes,
    pageSize,
    setOpen,
    setOpenEnableModal,
    setCurrentPromoCode,
  } = props;

  const [page, setPage] = useState(1);

  const handleOpenModal = (promoCode) => {
    setCurrentPromoCode({ ...promoCode });
    setOpen(true);
  };

  const handleOpenEnableModal = (promoCode) => {
    setCurrentPromoCode({ ...promoCode });
    setOpenEnableModal(true);
  };

  const handleChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const generatePromoCodes = () => {
    return promoCodes
      .sort((a, b) => b.id - a.id)
      .slice((page - 1) * pageSize, page * pageSize)
      .map((q) => (
        <Col key={q.id} md={8} sm={12} xs={24}>
          <PromoCodeCard
            {...q}
            setOpenModal={() => handleOpenModal(q)}
            setOpenEnableModal={handleOpenEnableModal}
          />
        </Col>
      ));
  };

  return (
    <div style={{ marginTop: "15px" }}>
      <Row gutter={25} justify="center">
        <Col xs={24}>
          <p className="text-center">
            Showing {page * pageSize - 47} -{" "}
            {promoCodes.length > page * pageSize
              ? page * pageSize
              : promoCodes.length}{" "}
            of {promoCodes.length}
          </p>
        </Col>
        {generatePromoCodes()}
        <Col xs={24}>
          <div className="text-center">
            <Pagination
              onChange={handleChange}
              total={promoCodes.length}
              pageSize={pageSize}
              showSizeChanger={false}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
}

PromoCodesList.defaultProps = {
  pageSize: 48,
};

export default PromoCodesList;
