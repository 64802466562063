import React, { useState, useEffect } from "react";
import { Row, Col, Skeleton } from "antd";
import { PageHeader } from "../../components/page-headers/page-headers";
import { Main } from "../styled";
import { Cards } from "../../components/cards/frame/cards-frame";
import { Button } from "../../components/buttons/buttons";
import FeatherIcon from "feather-icons-react";
import FilterRow from "./FilterRow";
import PromoCodesList from "./PromoCodesList";
import { connect } from "react-redux";
import { fetchPromoCodesAction } from "../../redux/actions/promoCodeActions";
import PromoCodeFormModal from "../../components/modals/PromoCodeFormModal";
import EnablePromoCodeModal from "../../components/modals/EnablePromoCodeModal";

const PromoCodesContainer = (props) => {
  const { promoCodes, fetchPromoCodes, loading } = props;

  useEffect(() => {
    if (!promoCodes || (promoCodes && promoCodes.length === 0)) {
      fetchPromoCodes();
    }
    // eslint-disable-next-line
  }, []);

  const [filteredPromoCodes, setFilteredPromoCodes] = useState(
    promoCodes ? [...promoCodes] : []
  );
  const [openModal, setOpenModal] = useState(false);
  const [openEnableModal, setOpenEnableModal] = useState(false);
  const [currentPromoCode, setCurrentPromoCode] = useState(null);

  const handleClick = () => {
    setCurrentPromoCode({});
    setOpenModal(true);
  };

  return (
    <>
      <PageHeader
        title="Promo Codes List"
        buttons={[
          <div key="6" className="page-header-actions">
            <Button size="small" type="primary" onClick={handleClick}>
              <FeatherIcon icon="plus" size={14} />
              Add New
            </Button>
          </div>,
        ]}
      />
      <Main>
        <Row gutter={25}>
          {loading && (
            <Col sm={24} xs={24}>
              <Cards headless>
                <Skeleton paragraph={{ rows: 20 }} active />
              </Cards>
            </Col>
          )}
          {!loading && (
            <>
              <Col sm={24} xs={24}>
                <FilterRow
                  setFilteredPromoCodes={setFilteredPromoCodes}
                  promoCodes={promoCodes}
                />
              </Col>
              <Col sm={24} xs={24}>
                <PromoCodesList
                  promoCodes={filteredPromoCodes ? [...filteredPromoCodes] : []}
                  setOpen={setOpenModal}
                  setOpenEnableModal={setOpenEnableModal}
                  setCurrentPromoCode={setCurrentPromoCode}
                />
              </Col>
            </>
          )}
        </Row>
        <PromoCodeFormModal
          promoCode={currentPromoCode}
          open={openModal}
          setOpen={setOpenModal}
        />
        <EnablePromoCodeModal
          promoCode={currentPromoCode}
          open={openEnableModal}
          setOpen={setOpenEnableModal}
        />
      </Main>
    </>
  );
};

let mapStateToProps = (state) => ({
  promoCodes: state.promoCodes.promoCodes,
  loading: state.promoCodes.loading,
});

let mapDispatchToProps = (dispatch) => ({
  fetchPromoCodes: () => dispatch(fetchPromoCodesAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PromoCodesContainer);
