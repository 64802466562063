import React from "react";
import { Modal } from "./antd-modals";
import { connect } from "react-redux";
import { updatePromoCodeAction } from "../../redux/actions/promoCodeActions";

function EnablePromoCodeModal(props) {
  const { promoCode, open, setOpen, updatePromoCode } = props;

  const handleSubmit = () => {
    if (promoCode && promoCode.id) {
      updatePromoCode(promoCode.id, { is_enabled: !promoCode.is_enabled }).then(
        (result) => {
          if (result && result.id) {
            setOpen(false);
          }
        }
      );
    }
  };

  return (
    <Modal
      title={
        promoCode && promoCode.is_enabled ? "Disable Promo Code" : "Enable Promo Code"
      }
      visible={open}
      onOk={() => handleSubmit()}
      onCancel={() => setOpen(false)}
      type="primary"
      footer={false}
    >
      <p>
        Are you sure you wish to {promoCode && promoCode.is_enabled ? "Disable" : "Enable"}{" "}
        this promo code?
      </p>
    </Modal>
  );
}

let mapDispatchToProps = (dispatch) => ({
  updatePromoCode: (id, formData) => dispatch(updatePromoCodeAction(id, formData)),
});

export default connect(null, mapDispatchToProps)(EnablePromoCodeModal);
