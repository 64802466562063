import React, { useEffect } from "react";
import { Modal } from "./antd-modals";
import PromoCodeForm from "../forms/PromoCodeForm/PromoCodeForm";
import { connect } from "react-redux";
import { Form } from "antd";
import {
  createPromoCodeAction,
  updatePromoCodeAction,
} from "../../redux/actions/promoCodeActions";

function PromoCodeFormModal(props) {
  const { promoCode, open, setOpen, updatePromoCode, createPromoCode } = props;

  const [form] = Form.useForm();

  const handleSubmit = () => {
    let data = form.getFieldsValue(true);
    if (data && data.discount) {
      data.discount = parseInt(data.discount * 100, 10);
    }

    if (promoCode && promoCode.id) {
      updatePromoCode(promoCode.id, data).then((result) => {
        if (result && result.id) {
          setOpen(false);
        }
      });
    } else {
      createPromoCode(data).then((result) => {
        if (result && result.id) {
          setOpen(false);
        }
      });
    }
  };

  useEffect(() => {
    form.resetFields();
  }, [promoCode, form]);

  return (
    <Modal
      title={promoCode && promoCode.id ? "Edit Promo Code" : "Create Promo Code"}
      visible={open}
      onOk={() => handleSubmit()}
      onCancel={() => setOpen(false)}
      type="primary"
      footer={false}
    >
      <PromoCodeForm {...promoCode} form={form} />
    </Modal>
  );
}

let mapDispatchToProps = (dispatch) => ({
  createPromoCode: (formData) => dispatch(createPromoCodeAction(formData)),
  updatePromoCode: (id, formData) => dispatch(updatePromoCodeAction(id, formData)),
});

export default connect(null, mapDispatchToProps)(PromoCodeFormModal);
