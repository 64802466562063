import React from "react";
import { Card, Form, Input, Button } from "antd";
import Heading from "../../heading/heading";
import FeatherIcon from "feather-icons-react";

export const RegisterForm = (props) => {
  const { handleSubmit, loading, registerErrors, showDisabledCard } = props;

  const [form] = Form.useForm();

  const generateErrors = () => {
    let showErrors = [];
    if (registerErrors.email) {
      showErrors = [...showErrors, ...registerErrors.email];
    }
    if (registerErrors.name) {
      showErrors = [...showErrors, ...registerErrors.name];
    }
    if (registerErrors.password) {
      showErrors = [...showErrors, ...registerErrors.password];
    }
    if (registerErrors.password_confirmation) {
      showErrors = [...showErrors, ...registerErrors.password_confirmation];
    }
    if (registerErrors.message) {
      showErrors = [...showErrors, registerErrors.message];
    }

    return showErrors.map((e) => (
      <div
        className="ant-form-item-explain-error"
        style={{ marginBottom: "10px" }}
      >
        * {e}
      </div>
    ));
  };

  return (
    <Form name="register" form={form} onFinish={handleSubmit} layout="vertical">
      <h4>Power Solar Quote Manager</h4>
      <Heading as="h3">Register</Heading>
      {showDisabledCard && (
        <Card>
          <FeatherIcon icon="info" className={"text-info"}></FeatherIcon>
          <p className="text-info">
            <strong>Your account has been created</strong>, but is currently
            disabled. Please contact an admin to enable it.
          </p>
        </Card>
      )}
      <br />
      <Form.Item
        name="email"
        rules={[{ message: "Email is required", required: true }]}
        initialValue=""
        label="Email Address"
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="name"
        rules={[{ message: "Name is required", required: true }]}
        initialValue=""
        label="Name"
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[{ message: "Password is required", required: true }]}
        initialValue=""
        label="Password"
      >
        <Input.Password placeholder="Password" />
      </Form.Item>
      <Form.Item
        name="password_confirmation"
        rules={[
          { message: "Password confirmation is required", required: true },
        ]}
        initialValue=""
        label="Confirm Password"
      >
        <Input.Password placeholder="Password" />
      </Form.Item>
      {generateErrors()}
      <Form.Item>
        <Button
          className="btn-signin"
          htmlType="submit"
          type="primary"
          size="large"
        >
          {loading ? "Loading..." : "Sign Up"}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default RegisterForm;
