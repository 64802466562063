import React from "react";
import { DatePicker, Form, Input } from "antd";
import moment from "moment";

function PromoCodeForm(props) {
  const {
    handleSubmit,
    id,
    name,
    code,
    discount,
    valid_from,
    valid_until,
    form,
  } = props;

  return (
    <Form
      name="editPromoCode"
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
    >
      {id && <p>ID: {id}</p>}
      <Form.Item
        name="name"
        rules={[{ message: "Name is required!", required: true }]}
        initialValue={name ? name : ""}
        label="Name"
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="code"
        rules={[{ message: "Code is required!", required: true }]}
        initialValue={code ? code : ""}
        label="Code"
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="discount"
        rules={[{ message: "Discount is required!", required: true }]}
        initialValue={discount ? discount / 100 : ""}
        label="Discount ($)"
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="valid_from"
        rules={[{ message: "Valid From is required!", required: true }]}
        initialValue={valid_from ? moment(valid_from) : ""}
        label="Valid From"
      >
        <DatePicker className="ant-input" />
      </Form.Item>
      <Form.Item
        name="valid_until"
        rules={[{ message: "Valid Until is required!", required: true }]}
        initialValue={valid_until ? moment(valid_until) : ""}
        label="Valid Until"
      >
        <DatePicker className="ant-input" />
      </Form.Item>

      {/* <Form.Item>
                <Button className="btn-signin" htmlType="submit" type="primary" size="large">
                {loading ? 'Loading...' : 'Sign In'}
                </Button>
            </Form.Item> */}
    </Form>
  );
}

export default PromoCodeForm;
