import { API_URL } from "../constants/apiURLConstants";
import {
  LOADING_PROMO_CODES,
  CLEAR_LOADING_PROMO_CODES,
  FETCH_PROMO_CODES,
  CREATE_PROMO_CODE,
  UPDATE_PROMO_CODE,
} from "../constants/promoCodeConstants";
import axios from "axios";
import { errorHandler } from "../helpers.js/helper";

export function fetchPromoCodesAction() {
  return (dispatch) => {
    dispatch({ type: LOADING_PROMO_CODES });
    return (
      axios
        .get(`${API_URL}promo-codes`, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        // eslint-disable-next-line consistent-return
        .then((responseJSON) => {
          if (responseJSON && responseJSON.data) {
            dispatch({ type: FETCH_PROMO_CODES, payload: responseJSON.data });
            return responseJSON.data;
          }
          dispatch({ type: CLEAR_LOADING_PROMO_CODES });
          return {};
        })
        .catch((error) => {
          errorHandler(dispatch, error, () => {
            dispatch({ type: CLEAR_LOADING_PROMO_CODES });
          });
        })
    );
  };
}

export function createPromoCodeAction(formData) {
  return (dispatch) => {
    dispatch({ type: LOADING_PROMO_CODES });
    return (
      axios
        .post(`${API_URL}promo-codes`, formData, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        // eslint-disable-next-line consistent-return
        .then((responseJSON) => {
          if (responseJSON && responseJSON.data && responseJSON.data.promo) {
            dispatch({
              type: CREATE_PROMO_CODE,
              payload: responseJSON.data.promo,
            });
            return responseJSON.data.promo;
          }
          dispatch({ type: CLEAR_LOADING_PROMO_CODES });
          return responseJSON;
        })
        .catch((error) => {
          return errorHandler(dispatch, error, () => {
            dispatch({ type: CLEAR_LOADING_PROMO_CODES });
            if (
              error.response &&
              error.response.data &&
              error.response.data.errors &&
              Object.keys(error.response.data.errors).length > 0
            ) {
              return error.response.data.errors;
            }
            return false;
          });
        })
    );
  };
}

export function updatePromoCodeAction(promoCodeId, formData) {
  return (dispatch) => {
    dispatch({ type: LOADING_PROMO_CODES });
    return (
      axios
        .patch(`${API_URL}promo-codes/${promoCodeId}`, formData, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        // eslint-disable-next-line consistent-return
        .then((responseJSON) => {
          if (responseJSON && responseJSON.data && responseJSON.data.promo) {
            dispatch({
              type: UPDATE_PROMO_CODE,
              payload: responseJSON.data.promo,
            });
            return responseJSON.data.promo;
          }
          dispatch({ type: CLEAR_LOADING_PROMO_CODES });
          return responseJSON;
        })
        .catch((error) => {
          return errorHandler(dispatch, error, () => {
            dispatch({ type: CLEAR_LOADING_PROMO_CODES });
            if (
              error.response &&
              error.response.data &&
              error.response.data.errors &&
              Object.keys(error.response.data.errors).length > 0
            ) {
              return error.response.data.errors;
            }
            return false;
          });
        })
    );
  };
}
